import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Edelun`}</strong>{` is the largest island on `}<a parentName="p" {...{
        "href": "/Naos",
        "title": "Naos"
      }}>{`Naos`}</a>{`, off the North-West coast of `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`. There are three countries residing on Edelun: `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{`, `}<a parentName="p" {...{
        "href": "/Esharn",
        "title": "Esharn"
      }}>{`Esharn`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Volkharn",
        "title": "Volkharn"
      }}>{`Volkharn`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      